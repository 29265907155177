import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import options from "./options";
import profile from "./profile";
import office from "./office";
import score from "./score"
import admin from "./admin"
import starlord from './starlord'
import bniGfx from './bni-gfx'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    options,
    admin,
    profile,
    score,
    office,
    starlord,
    bniGfx,
  },
});
