import axios from "axios";
import download from "downloadjs";

const bniGfx = {
  namespaced: true,

  state: {
    mailLogs: "",
    hover: false,
    loading: false,
    hoverNotif: false,
    dataWeb: [],
  },

  getters: {
    dataWeb: state => state.dataWeb,
    hover: state => state.hover
  },

  mutations: {
    basic(state, payload) {
      state[payload.key] = payload.value;
    },
    setDataWeb(state, payload) {
      state.hover = false
      state.dataWeb = payload
    },
    clearDataWeb(state) {
      state.dataWeb = []
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setHover(state, payload) {
      state.hover = payload
    },
  },

  actions: {
      fetchAllMailLogs({ commit }, page) {
        const fetchMailLogsUrl =
        process.env.VUE_APP_BASE_URL + "/api/getMailLogs";
        return axios
          .get(fetchMailLogsUrl, {
            headers: {
              token: localStorage.getItem("token"),
            },
            params: {
                limit: '10',
                ...(page ? { page: page } : {})
            },
        })
          .then((r) => {
              commit("basic", {
                key: "mailLogs",
                value: r.data,
              });
              return r;
          })
          .catch((error) => {
            console.log(error, 'error');
          });
      },
      fetchOneMailLog({ commit }, id) {
        const fetchOneMailLogUrl =
        process.env.VUE_APP_BASE_URL + "/api/readAppUser/" + id;
        return axios
          .get(fetchOneMailLogUrl, {
            headers: {
              token: localStorage.getItem("token"),
            },
          })
          .then((r) => {
              return r.data;
          })
          .catch((error) => {
            console.log(error, 'error');
          });
      },
      searchMailLogs({ commit }, query) {
        const page = 1
        const searchMailLogsUrl =
        process.env.VUE_APP_BASE_URL + "/api/mailLogs/search";
        return axios
          .get(searchMailLogsUrl, {
            headers: {
              token: localStorage.getItem("token"),
            },
            params: {
                limit: '10',
                nik: query,
                ...(page ? { page: page } : {})
            },
        })
          .then((r) => {
              commit("basic", {
                key: "mailLogs",
                value: r.data,
              });
              return r;
          })
          .catch((error) => {
            console.log(error, 'error');
          });
      },
      fetchSFTPData(context, payload) {
        const fetchSFTPDataUrl = process.env.VUE_APP_SECONDARY_URL + "/api/gfx/opendir?dirname=";
        if (payload[1] == false) {
          axios
            .get(fetchSFTPDataUrl + payload[0], {
              auth: {
                username: process.env.VUE_APP_GFX_USERNAME,
                password: process.env.VUE_APP_GFX_PASSWORD,
              },
            })
            .then(({ data }) => {
                context.commit('setDataWeb', data)
                context.commit("setLoading", false)
            })
            .catch(function (error) {
              context.commit("setLoading", false)
            })
        }
        else {
          console.log(context.state.dataWeb, "tes notif")
          let newTemp = []
          axios
            .get(fetchSFTPDataUrl + payload[1].from, {
              auth: {
                username: process.env.VUE_APP_GFX_USERNAME,
                password: process.env.VUE_APP_GFX_PASSWORD,
              },
            })
            .then(({ data }) => {
              context.state.dataWeb.forEach(element => {
                if (data.data !== null) {
                  if (element.name === payload[1].from) {
                    element.notif = []
                    data.data.forEach(el => {
  
                      element.notif.push({ name: el.name, status: el.is_download })
                      console.log(el.is_download, 'is')
  
                    });
                  }
                }
  
                newTemp.push(element)
                context.commit("setLoading", false)
  
              });
            })
        }
      },
      downloadFileSFTP({ dispatch }, payload) {
        const downloadUrl =
        process.env.VUE_APP_SECONDARY_URL +
        `/api/gfx/download?filename=${payload.route}`
      return axios
        .get(downloadUrl + `/${payload.name}`, {
          auth: {
            username: process.env.VUE_APP_GFX_USERNAME,
            password: process.env.VUE_APP_GFX_PASSWORD,
          },
          responseType: "blob",
        })
        .then(({ data }) => {
          dispatch("fetchSFTPData", [
            payload.route,
            false,
          ]);
          let fileName = payload.name.split(".")[
            payload.name.split(".").length - 1
          ];
          if (fileName == "pdf") {
            download(data, payload.name, "application/pdf");
          } else if (fileName == "png") {
            download(data, payload.name, "image/png");
          } else if (fileName == "jpeg" || fileName == "jpg") {
            download(data, payload.name, "image/jpeg");
          } else if (fileName == "csv") {
            download(data, payload.name, "text/csv");
          } else if (fileName == "csv") {
            download(data, payload.name, "image/jpeg");
          } else if (fileName == "xls") {
            download(data, payload.name, "application/vnd.ms-excel");
          } else if (fileName == "xlsx") {
            download(
              data,
              payload.name,
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            );
          }
        })
        .catch(function(error) {
          return error
        });
      },
      deleteDirectorySFTP({ dispatch }, payload) {
        const deleteUrl =
        process.env.VUE_APP_SECONDARY_URL +
        `/api/gfx/directory?dirname=${payload.route}`;
      return axios
        .delete(deleteUrl + `/${payload.name}`, {
          auth: {
            username: process.env.VUE_APP_GFX_USERNAME,
            password: process.env.VUE_APP_GFX_PASSWORD,
          },
        })
        .then(({ data }) => {
          dispatch("fetchSFTPData", [
            payload.route,
            false,
          ]);
          return 'success'
        })
        .catch(function(error) {
          return error
        });
      },
      deleteFileSFTP({ dispatch }, payload) {
        const deleteUrl =
        process.env.VUE_APP_SECONDARY_URL +
        `/api/gfx/file?filename=${payload.route}`;
      return axios
        .delete(deleteUrl + `/${payload.name}`, {
          auth: {
            username: process.env.VUE_APP_GFX_USERNAME,
            password: process.env.VUE_APP_GFX_PASSWORD,
          },
        })
        .then(({ data }) => {
          dispatch("fetchSFTPData", [
            payload.route,
            false,
          ]);
          return 'success'
        })
        .catch(function(error) {
          return error
        });
      },
      uploadFileSFTP({ commit }, payload) {
        let heading = {
          "Content-Type": "multipart/form-data",
        };
        const uploadFileUrl =
        process.env.VUE_APP_SECONDARY_URL +
        `/api/gfx/upload`;
      return axios
        .post(uploadFileUrl, payload, {
          headers: heading,
          auth: {
            username: process.env.VUE_APP_GFX_USERNAME,
            password: process.env.VUE_APP_GFX_PASSWORD,
          },
        })
        .then(({ data }) => {
          return 'success'
        })
        .catch(function(error) {
          return error
        });
      },
  },
};

export default bniGfx;
