import axios from "axios";

const profile = {
    namespaced: true,

    state: {
        dataFdc: [],
    },

    mutations: {
        basic(state, payload) {
            console.log(payload.value, "tessst")
            state[payload.key] = payload.value;
        },
    },

    actions: {
        requestFdc({ commit, dispatch }, payload) {
            const fdcUrl =
                process.env.VUE_APP_BASE_URL + "/api/checkSkoring";
            return axios
                .post(fdcUrl, payload, {
                    headers: {
                        token: localStorage.getItem("token"),
                    },
                })
                .then((r) => {
                    if (r.data) {
                        if (r.data) {
                            console.log(r.data, "tessstt")
                            commit("basic", {
                                key: "dataFdc",
                                value: r.data,
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // alert(error.message);
                });
        },
    },
};

export default profile;
