import axios from "axios";
import router from "../router/index.js";

const options = {
  namespaced: true,

  state: {
    cities: "",
    provinces: "",
    religions: "",
    workFields: "",
    paymentFrequencies: "",
    collaterals: "",
    legalEntities: "",
    genders: "",
    paymentTypes: "",
    jobs: "",
    incomes: "",
    educations: "",
    workExperiences: "",
    loans: "",
    loanApplications: "",
    maritalStatuses: "",
  },

  mutations: {
    basic(state, payload) {
      state[payload.key] = payload.value;
    },
  },

  actions: {
    fetchCities({ commit }) {
      const citiesGetUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/mst_kota";
      return axios
        .get(citiesGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "cities",
              value: r.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchProvinces({ commit }) {
      const provincesGetUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/mst_provinsi";
      return axios
        .get(provincesGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "provinces",
              value: r.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchReligions({ commit }) {
      const religionsGetUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/mst_agama";
      return axios
        .get(religionsGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "religions",
              value: r.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchWorkFields({ commit }) {
      const workFieldsGetUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/mst_bidang_pekerjaan";
      return axios
        .get(workFieldsGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "workFields",
              value: r.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchPaymentFrequencies({ commit }) {
      const paymentFrequenciesGetUrl =
        process.env.VUE_APP_BASE_URL +
        "/api/backoffice/mst_frekuensi_pembayaran";
      return axios
        .get(paymentFrequenciesGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "paymentFrequencies",
              value: r.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchCollaterals({ commit }) {
      const collateralsGetUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/mst_jenis_agunan";
      return axios
        .get(collateralsGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "collaterals",
              value: r.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchLegalEntities({ commit }) {
      const legalEntitiesGetUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/mst_jenis_badan_hukum";
      return axios
        .get(legalEntitiesGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "legalEntities",
              value: r.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchGenders({ commit }) {
      const gendersGetUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/mst_jenis_kelamin";
      return axios
        .get(gendersGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "genders",
              value: r.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchPaymentTypes({ commit }) {
      const paymentTypesGetUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/mst_jenis_pembayaran";
      return axios
        .get(paymentTypesGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "paymentTypes",
              value: r.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchJobs({ commit }) {
      const jobsGetUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/mst_pekerjaan";
      return axios
        .get(jobsGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r.data)
          if (r.data) {
            commit("basic", {
              key: "jobs",
              value: r.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchIncomes({ commit }) {
      const incomesGetUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/mst_pendapatan";
      return axios
        .get(incomesGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "incomes",
              value: r.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchEducations({ commit }) {
      const educationsGetUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/mst_pendidikan";
      return axios
        .get(educationsGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "educations",
              value: r.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchWorkExperiences({ commit }) {
      const workExperiencesGetUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/mst_pengalaman_kerja";
      return axios
        .get(workExperiencesGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "workExperiences",
              value: r.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchLoans({ commit }) {
      const loansGetUrl =
        process.env.VUE_APP_BASE_URL +
        "/api/backoffice/mst_penggunaan_pinjaman";
      return axios
        .get(loansGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "loans",
              value: r.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchLoanApplications({ commit }) {
      const loanApplicationsGetUrl =
        process.env.VUE_APP_BASE_URL +
        "/api/backoffice/status_pengajuan_pinjaman";
      return axios
        .get(loanApplicationsGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
        
          if (r.data) {
            commit("basic", {
              key: "loanApplications",
              value: r.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchMaritalStatuses({ commit }) {
      const maritalStatusesGetUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/mst_status_perkawinan";
      return axios
        .get(maritalStatusesGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "maritalStatuses",
              value: r.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
  },
};

export default options;
