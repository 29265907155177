<template>
  <v-app>
    <v-main class="noHorizontalScroll">
      <div class="baseBg" />
      <app-bar-main
        v-if="$route.name !== 'Login'"
        :isDrawerOpen="drawerStatus"
      />
      <div v-if="$route.name !== 'Login'" class="mainLayout">
        <router-view />
      </div>
      <div v-else>
        <router-view />
      </div>
    </v-main>
    <footer-main v-if="$route.name !== 'Login'" />
  </v-app>
</template>

<script>
import AppBarMain from "./components/AppBar/AppBarMain.vue";
import FooterMain from "./components/Footer.vue";

export default {
  name: "App",

  components: {
    AppBarMain,
    FooterMain,
  },

  data() {
    return {
      drawerStatus: null,
    };
  },
};
</script>
