import axios from "axios";
import router from "../router/index.js";

const auth = {
  namespaced: true,

  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    userName: localStorage.getItem("username") || "",
  },

  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token) {
      state.status = "success";
      state.token = token;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
    basic(state, payload) {
      state[payload.key] = payload.value;
    },
  },

  getters: {
    isLoggedIn: (state) => !!state.token,
  },

  actions: {
    login({ commit, state }, payload) {
      const loginUrl = process.env.VUE_APP_BASE_URL + "/api/admin/login";
      commit("auth_request");
      return axios
        .post(loginUrl, payload)
        .then((r) => {
          if (r.data) {
            const token = r.data.access_token;
            const username = r.data.username;
            localStorage.setItem("token", token);
            localStorage.setItem("username", username);
            axios.defaults.headers.common[
              "Authorization"
            ] = localStorage.getItem("token");
            commit("auth_success", token);
            commit("basic", {
              key: "token",
              value: token,
            });
            commit("basic", {
              key: "userName",
              value: username,
            });
            router.push("/dashboard");
          }
        })
        .catch((error) => {
          commit("auth_error");
          localStorage.removeItem("token");
          localStorage.removeItem("username");
        });
    },
    logout({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit("logout");
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
        router.push("/login");
      });
    },
  },
};

export default auth;
