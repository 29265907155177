import axios from "axios";

const profile = {
    namespaced: true,

    state: {
        admins: [],
        adminAllRoles: [],
        AdminUnsel: [],
        adminPermissions: []
    },

    mutations: {
        basic(state, payload) {
            console.log(payload.value, "tessst")
            state[payload.key] = payload.value;
        },
    },

    actions: {
        addRoles({ commit, dispatch }, payload) {
            const fdcUrl =
                process.env.VUE_APP_BASE_URL + "/api/admin/addRoles";
            return axios
                .post(fdcUrl, payload, {
                    headers: {
                        token: localStorage.getItem("token"),
                    },
                })
                .then((r) => {
                    console.log(r, "permisi")
                    if (r.data) {
                        if (r.data) {
                            console.log(r.data, "tessstt")

                        }
                    }

                    return r.data
                })
                .catch((error) => {
                    console.log(error);
                    // alert(error.message);
                });
        },
        getAdminUnSel({ commit, dispatch }, payload) {
            const fdcUrl =
                process.env.VUE_APP_BASE_URL + "/api/admin/getAdminUnsel";
            return axios
                .get(fdcUrl, {
                    headers: {
                        token: localStorage.getItem("token"),
                    },
                })
                .then((r) => {
                    console.log(r, "permisi")
                    if (r.data) {
                        if (r.data) {
                            console.log(r.data, "tessstt")
                            commit("basic", {
                                key: "AdminUnsel",
                                value: r.data,
                            });
                        }
                    }

                    return r.data
                })
                .catch((error) => {
                    console.log(error);
                    // alert(error.message);
                });
        },
        getAdminPermissions({ commit, dispatch }, payload) {
            const fdcUrl =
                process.env.VUE_APP_BASE_URL + "/api/admin/adminPermissions";
            return axios
                .get(fdcUrl, {
                    headers: {
                        token: localStorage.getItem("token"),
                    },
                })
                .then((r) => {
                    console.log(r)
                    if (r.data) {
                        if (r.data) {
                            console.log(r.data.data, "tessstt")
                            commit("basic", {
                                key: "adminPermissions",
                                value: r.data.data,
                            });
                        }
                    }
                    for (const iterator of r.data.data) {
                        // console.log(iterator, "halo")
                    }
                    return r.data.data
                })
                .catch((error) => {
                    console.log(error);
                    // alert(error.message);
                });
        },
        getAdmins({ commit, dispatch }, payload) {
            const fdcUrl =
                process.env.VUE_APP_BASE_URL + "/api/admin";
            return axios
                .get(fdcUrl, {
                    headers: {
                        token: localStorage.getItem("token"),
                    },
                })
                .then((r) => {
                    console.log(r)
                    if (r.data) {
                        if (r.data) {
                            console.log(r.data, "tessstt")
                            commit("basic", {
                                key: "admins",
                                value: r.data,
                            });
                            return r
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // alert(error.message);
                });
        },
        getAdminRoles({ commit, dispatch }, payload) {
            const fdcUrl =
                process.env.VUE_APP_BASE_URL + "/api/admin/adminAllRoles";
            return axios
                .get(fdcUrl, {
                    headers: {
                        token: localStorage.getItem("token"),
                    },
                })
                .then((r) => {
                    console.log(r, "inevosi")
                    if (r.data) {
                        if (r.data) {
                            console.log(r.data, "tessstt")
                            commit("basic", {
                                key: "adminAllRoles",
                                value: r.data,
                            });
                            return r
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // alert(error.message);
                });
        },
        postAdmins({ commit, dispatch }, payload) {
            const fdcUrl =
                process.env.VUE_APP_BASE_URL + "/api/admin";
            return axios
                .post(fdcUrl, payload, {
                    headers: {
                        token: localStorage.getItem("token"),
                    },
                })
                .then((r) => {
                    console.log(r)
                    if (r.data) {
                        if (r.data) {
                            console.log(r.data, "admin berhasil")
                            // commit("basic", {
                            //     key: "admins",
                            //     value: r.data,
                            // });
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // alert(error.message);
                });
        },
    },
};

export default profile;
