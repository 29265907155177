import axios from "axios";

const profile = {
  namespaced: true,

  state: {
    companyProfileData: "",
  },

  mutations: {
    basic(state, payload) {
      state[payload.key] = payload.value;
    },
  },

  actions: {
    fetchCompanyProfile({ commit }) {
      const companyProfileGetUrl = process.env.VUE_APP_BASE_URL + "/api/one";
      return axios
        .get(companyProfileGetUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            console.log(r.data, "tessstt")
            commit("basic", {
              key: "companyProfileData",
              value: r.data[0],
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    editCompanyProfile({ commit, dispatch }, payload) {
      const companyProfilePutUrl =
        process.env.VUE_APP_BASE_URL + "/api/" + payload.id;
      axios
        .put(companyProfilePutUrl, payload, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            dispatch("fetchCompanyProfile");
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
  },
};

export default profile;
