<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="(val) => $emit('update:is-drawer-open', val)"
  >
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require('@/assets/images/logo/dompetkilat.png')"
          max-width="190px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
      </router-link>
    </div>

    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link
        title="Dashboard"
        :to="{ name: 'Dashboard' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <nav-menu-section-title title="COMPANY PROFILE"></nav-menu-section-title>
      <nav-menu-link
        title="Data CP"
        :to="{ name: 'Control' }"
        :icon="icons.mdiWeb"
      ></nav-menu-link>
      <nav-menu-section-title title="SETTINGS"></nav-menu-section-title>
      <nav-menu-link
        title="List Admin"
        :to="{ name: 'Admin' }"
        :icon="icons.mdiAccountMultiple"
      ></nav-menu-link>
      <nav-menu-link
        title="List Admin Roles"
        :to="{ name: 'AdminRoles' }"
        :icon="icons.mdiAccountMultiple"
      ></nav-menu-link>
      <nav-menu-section-title title="DATA DK"></nav-menu-section-title>
      <nav-menu-link
        title="Report Pusdafil"
        :to="{ name: 'ReportPusdafil' }"
        :icon="icons.mdiChartBoxOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="List Gabungan"
        :to="{ name: 'ListCombination' }"
        :icon="icons.mdiFormatListBulleted"
      ></nav-menu-link>
      <nav-menu-link
        title="List Pengguna"
        :to="{ name: 'ListUser' }"
        :icon="icons.mdiAccountMultiple"
      ></nav-menu-link>
      <nav-menu-link
        title="List Pengajuan"
        :to="{ name: 'ListSubmission' }"
        :icon="icons.mdiCashPlus"
      ></nav-menu-link>
      <nav-menu-link
        title="List Pemberian"
        :to="{ name: 'ListAdministration' }"
        :icon="icons.mdiCash"
      ></nav-menu-link>
      <nav-menu-link
        title="List Transaksi"
        :to="{ name: 'ListTransaction' }"
        :icon="icons.mdiCashMultiple"
      ></nav-menu-link>
      <nav-menu-link
        title="List Pembayaran"
        :to="{ name: 'ListPayment' }"
        :icon="icons.mdiCashCheck"
      ></nav-menu-link>
      <nav-menu-section-title title="SCORING DK"></nav-menu-section-title>
      <nav-menu-link
        title="FDC"
        :to="{ name: 'Fdc' }"
        :icon="icons.mdiAccountMultiple"
      ></nav-menu-link>
      <nav-menu-link
        title="PEFINDO"
        :to="{ name: 'Pefindo' }"
        :icon="icons.mdiAccountMultiple"
      ></nav-menu-link>
      <nav-menu-section-title title="MIDDLEWARE"></nav-menu-section-title>
      <nav-menu-link
        title="List Borrower"
        :to="{ name: 'ListBorrowerMiddleware' }"
        :icon="icons.mdiFormatListBulleted"
      ></nav-menu-link>
      <nav-menu-section-title title="BNI GFX"></nav-menu-section-title>
      <nav-menu-link
        title="Log Email"
        :to="{ name: 'MailLogs' }"
        :icon="icons.mdiEmail"
      ></nav-menu-link>
      <nav-menu-link
        title="SFTP"
        :to="{ name: 'SFTPRoot' }"
        :icon="icons.mdiFileCabinet"
      ></nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiWeb,
  mdiAccountMultiple,
  mdiCashMultiple,
  mdiCashPlus,
  mdiCash,
  mdiCashCheck,
  mdiChartBoxOutline,
  mdiEmail,
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiFormatListBulleted,
  mdiFileCabinet,
} from "@mdi/js";
import NavMenuSectionTitle from "./NavMenuSectionTitle.vue";
import NavMenuGroup from "./NavMenuGroup.vue";
import NavMenuLink from "./NavMenuLink.vue";

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      icons: {
        mdiWeb,
        mdiAccountMultiple,
        mdiCashMultiple,
        mdiCashPlus,
        mdiCash,
        mdiCashCheck,
        mdiChartBoxOutline,
        mdiEmail,
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiFormatListBulleted,
        mdiFileCabinet,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: white;
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      background-color: transparent;
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
