import axios from "axios";

const office = {
  namespaced: true,

  state: {
    listUsers: "",
    listLender: "",
    listBorrower: "",
    listTransaction: "",
    listLoans: "",
    // newIDPengguna: 0,
    // newIDBorrower: 0,
    // newIDLender: 0,
    listPayment: "",
    listSubmission: "",
    listAdministration: "",
    allBorrower: "",
    allLender: "",
    allLoan: "",
    allTransaction: "",
    currentBorrower: "",
    currentUser: {},
    currentLender: "",
    currentLoan: "",
    currentLoanLender: "",
    currentTransaction: "",
    currentPayment: "",
    monthlyLender: "",
  },

  mutations: {
    basic(state, payload) {
      state[payload.key] = payload.value;
    },
  },

  actions: {
    fetchAllBorrower({ commit }) {
      const allBorrowerUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/getBorrowers";
      return axios
        .get(allBorrowerUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "allBorrower",
              value: r.data,
            });
            return r;
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchAllLender({ commit }) {
      const allLenderUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/getLenders";
      return axios
        .get(allLenderUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r);
          if (r.data) {
            commit("basic", {
              key: "allLender",
              value: r.data,
            });
            return r;
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchAllLoan({ commit }) {
      const allLoanUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/getLoans";
      return axios
        .get(allLoanUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r);
          if (r.data) {
            commit("basic", {
              key: "allLoan",
              value: r.data,
            });
            return r;
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchAllTransaction({ commit }) {
      const allTransactionUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/getTransactions";
      return axios
        .get(allTransactionUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r);
          if (r.data) {
            commit("basic", {
              key: "allTransaction",
              value: r.data,
            });
            return r;
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchListLender({ commit }, pagination) {
      const listLenderUrl =
        process.env.VUE_APP_BASE_URL +
        "/api/internal/getLender/" +
        pagination.firstPage +
        "/" +
        pagination.lastPage;
      return axios
        .get(listLenderUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "listLender",
              value: r.data,
            });
            return r;
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchListBorrower({ commit }, pagination) {
      const listBorrowerUrl =
        process.env.VUE_APP_BASE_URL +
        "/api/internal/getBorrower/" +
        pagination.firstPage +
        "/" +
        pagination.lastPage;
      return axios
        .get(listBorrowerUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r);
          if (r.data) {
            commit("basic", {
              key: "listBorrower",
              value: r.data,
            });
            return r;
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchListTransaction({ commit }, pagination) {
      const listTransactionUrl =
        process.env.VUE_APP_BASE_URL +
        "/api/internal/getTransaksi/" +
        pagination.firstPage +
        "/" +
        pagination.lastPage;
      return axios
        .get(listTransactionUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "listTransaction",
              value: r.data,
            });
            return r;
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchListPayment({ commit }, pagination) {
      const listPaymentUrl =
        process.env.VUE_APP_BASE_URL +
        "/api/internal/getPembayaran/" +
        pagination.firstPage +
        "/" +
        pagination.lastPage;
      return axios
        .get(listPaymentUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "listPayment",
              value: r.data,
            });
            return r;
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchListSubmission({ commit }, pagination) {
      const listSubmissionUrl =
        process.env.VUE_APP_BASE_URL +
        "/api/internal/getPengajuan/" +
        pagination.firstPage +
        "/" +
        pagination.lastPage;
      return axios
        .get(listSubmissionUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "listSubmission",
              value: r.data,
            });
            return r;
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchListAdministration({ commit }, pagination) {
      const listAdministrationUrl =
        process.env.VUE_APP_BASE_URL +
        "/api/internal/getTransaksi/" +
        pagination.firstPage +
        "/" +
        pagination.lastPage;
      return axios
        .get(listAdministrationUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "listAdministration",
              value: r.data,
            });
            return r;
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchUser({ commit }, id) {
      const borrowerUrl =
        process.env.VUE_APP_BASE_URL + "/api/internal/getPengguna/" + id;
      return axios
        .get(borrowerUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log("user", r);
          if (r.data) {
            commit("basic", {
              key: "currentUser",
              value: r.data[0],
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchBorrower({ commit }, id) {
      const borrowerUrl =
        process.env.VUE_APP_BASE_URL + "/api/internal/getBorrowerForEdit/" + id;
      return axios
        .get(borrowerUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r);
          if (r.data) {
            commit("basic", {
              key: "currentBorrower",
              value: r.data[0],
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchLender({ commit }, id) {
      const lenderUrl =
        process.env.VUE_APP_BASE_URL + "/api/internal/getLender/" + id;
      return axios
        .get(lenderUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r);
          if (r.data) {
            commit("basic", {
              key: "currentLender",
              value: r.data[0],
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchLenderForEdit({ commit }, id) {
      const lenderUrl =
        process.env.VUE_APP_BASE_URL + "/api/internal/getLenderForEdit/" + id;
      return axios
        .get(lenderUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r);
          if (r.data) {
            commit("basic", {
              key: "currentLender",
              value: r.data[0],
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchLoan({ commit }, id) {
      const lenderUrl =
        process.env.VUE_APP_BASE_URL + "/api/internal/getPengajuan/" + id;
      return axios
        .get(lenderUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r.data[0]);
          if (r.data) {
            commit("basic", {
              key: "currentLoan",
              value: r.data[0],
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchLoanLender({ commit }, id) {
      const lenderUrl =
        process.env.VUE_APP_BASE_URL + "/api/internal/getPemberian/" + id;
      return axios
        .get(lenderUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r.data[0]);
          if (r.data) {
            commit("basic", {
              key: "currentLoanLender",
              value: r.data[0],
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchTransaction({ commit }, id) {
      const lenderUrl =
        process.env.VUE_APP_BASE_URL + "/api/internal/getTransaksi/" + id;
      return axios
        .get(lenderUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "currentTransaction",
              value: r.data[0],
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchPayment({ commit }, id) {
      const lenderUrl =
        process.env.VUE_APP_BASE_URL + "/api/internal/getPembayaran/" + id;
      return axios
        .get(lenderUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "currentPayment",
              value: r.data[0],
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    fetchMonthlyLender({ commit }, payload) {
      const monthlyLenderUrl =
        process.env.VUE_APP_BASE_URL +
        "/api/internal/monthlyLender/" +
        payload.id +
        "/" +
        payload.month +
        "/" +
        payload.year;
      return axios
        .get(monthlyLenderUrl, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "monthlyLender",
              value: r.data,
            });
            return r;
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.message);
        });
    },
    addNewUser({ commit, state }, payload) {
      const addNewUserUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/pengguna";
      return axios
        .post(addNewUserUrl, payload, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r, "sukses");
          commit("basic", {
            key: "newIDPengguna",
            value: r.data.insertId,
          });
          localStorage.setItem("newIDPengguna", r.data.insertId);
          return r;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    addNewTransaction({ commit, state }, payload) {
      const addNewTransactionUrl =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/pinjam_meminjam";
      return axios
        .post(addNewTransactionUrl, payload, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r, "sukses");
          return r;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    addNewBorrower({ commit, state }, payload) {
      const url = process.env.VUE_APP_BASE_URL + "/api/backoffice/borrower";
      return axios
        .post(url, payload, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r, "sukses");
          // commit("basic", {
          //   key: "newIDBorrower",
          //   value: r.data.insertId,
          // });
          localStorage.removeItem("newIDPengguna");

          return r;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    addNewLender({ commit, state }, payload) {
      const url = process.env.VUE_APP_BASE_URL + "/api/backoffice/lender";
      return axios
        .post(url, payload, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r, "sukses");
          // commit("basic", {
          //   key: "newIDLender",
          //   value: r.data.insertId,
          // });
          localStorage.removeItem("newIDPengguna");

          return r;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    addNewLoan({ commit, state }, payload) {
      console.log("payload", payload);
      const url =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/pengajuan_pinjaman";
      return axios
        .post(url, payload, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r, "sukses");
          return r;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    addNewLoanLender({ commit, state }, payload) {
      const url =
        process.env.VUE_APP_BASE_URL +
        "/api/backoffice/pengajuan_pemberian_pinjaman";
      return axios
        .post(url, payload, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r, "sukses");
          return r;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    addNewPayment({ commit, state }, payload) {
      const url =
        process.env.VUE_APP_BASE_URL + "/api/backoffice/pembayaran_pinjaman";
      return axios
        .post(url, payload, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r, "sukses");
          return r;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },

    getAllUsers({ commit, state }, payload) {
      const url = process.env.VUE_APP_BASE_URL + "/api/backoffice/getUsers";
      return axios
        .get(url, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r);
          if (r.data) {
            commit("basic", {
              key: "listLender",
              value: r.data.lender,
            });
            commit("basic", {
              key: "listBorrower",
              value: r.data.borrower,
            });
            commit("basic", {
              key: "listLoans",
              value: r.data.loan,
            });
            commit("basic", {
              key: "listTransaction",
              value: r.data.transaction,
            });
            return r;
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    getUsersOnly({ commit, state }, payload) {
      const url = process.env.VUE_APP_BASE_URL + "/api/backoffice/fetchUsers";
      return axios
        .get(url, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          if (r.data) {
            commit("basic", {
              key: "listUsers",
              value: r.data,
            });
            return r;
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    editUser({ commit, state }, payload) {
      const { id, data } = payload;
      const addNewUserUrl =
        process.env.VUE_APP_BASE_URL + `/api/backoffice/pengguna/${id}`;
      return axios
        .put(addNewUserUrl, data, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r, "sukses");
          return r;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    editTransaction({ commit, state }, payload) {
      const { id, data } = payload;
      const addNewTransactionUrl =
        process.env.VUE_APP_BASE_URL + `/api/backoffice/pinjam_meminjam/${id}`;
      return axios
        .put(addNewTransactionUrl, data, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r, "sukses");
          return r;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    editBorrower({ commit, state }, payload) {
      const { id, data } = payload;
      const url =
        process.env.VUE_APP_BASE_URL + `/api/backoffice/borrower/${id}`;
      return axios
        .put(url, data, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r, "sukses");
          return r;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    editLender({ commit, state }, payload) {
      const { id, data } = payload;

      const url = process.env.VUE_APP_BASE_URL + `/api/backoffice/lender/${id}`;
      return axios
        .put(url, data, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r, "sukses");
          return r;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    editLoan({ commit, state }, payload) {
      const { id, data } = payload;

      const url =
        process.env.VUE_APP_BASE_URL +
        `/api/backoffice/pengajuan_pinjaman/${id}`;
      return axios
        .put(url, data, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r, "sukses");
          return r;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    editLoanLender({ commit, state }, payload) {
      const { id, data } = payload;

      const url =
        process.env.VUE_APP_BASE_URL +
        `/api/backoffice/pengajuan_pemberian_pinjaman/${id}`;
      return axios
        .put(url, data, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r, "sukses");
          return r;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    editPayment({ commit, state }, payload) {
      const { id, data } = payload;
      const url =
        process.env.VUE_APP_BASE_URL +
        `/api/backoffice/pembayaran_pinjaman/${id}`;
      return axios
        .put(url, data, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r, "sukses");
          return r;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    addExcel({ commit, state }, payload) {
      console.log("payload", payload);
      const url =
        process.env.VUE_APP_BASE_URL + "/api/internal/bulkInputPusdafil";
      return axios
        .post(url, payload, {
          headers: {
            token: localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((r) => {
          return r;
        })
        .catch((error) => {
          return error.response
        });
    },
    fetchDataPefindo({ commit, state }, payload) {
      const url = process.env.VUE_APP_BASE_URL + "/api/xml/getdata";
      return axios
        .post(url, payload, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r, "sukses");
          return r;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    fetchReportPefindo({ commit, state }, payload) {
      const url = process.env.VUE_APP_BASE_URL + "/api/xml/report";
      return axios
        .post(url, payload, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((r) => {
          console.log(r, "sukses");
          return r;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
  },
};

export default office;
