import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { requiresAuth: true },
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    meta: { requiresAuth: true },
    component: () => import("../views/Administration/Admin.vue"),
  },
  {
    path: "/input-admin",
    name: "Input Admin",
    meta: { requiresAuth: true },
    component: () => import("../views/Administration/InputAdmin.vue"),
  },
  {
    path: "/admin-roles",
    name: "AdminRoles",
    meta: { requiresAuth: true },
    component: () => import("../views/Administration/AdminRoles.vue"),
  },
  {
    path: "/input-admin-roles",
    name: "Input Admin Roles",
    meta: { requiresAuth: true },
    component: () => import("../views/Administration/InputAdminRoles.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/control",
    name: "Control",
    meta: { requiresAuth: true },
    component: () => import("../views/CompanyProfile/Control.vue"),
  },
  {
    path: "/fdc",
    name: "Fdc",
    meta: { requiresAuth: true },
    component: () => import("../views/Scoring/Fdc.vue"),
  },
  {
    path: "/pefindo",
    name: "Pefindo",
    meta: { requiresAuth: true },
    component: () => import("../views/Scoring/Pefindo.vue"),
  },
  {
    path: "/list-user",
    name: "ListUser",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/ListUser.vue"),
  },
  {
    path: "/list-transaction",
    name: "ListTransaction",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/ListTransaction.vue"),
  },
  {
    path: "/list-payment",
    name: "ListPayment",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/ListPayment.vue"),
  },
  {
    path: "/list-submission",
    name: "ListSubmission",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/ListSubmission.vue"),
  },
  {
    path: "/list-administration",
    name: "ListAdministration",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/ListAdministration.vue"),
  },
  {
    path: "/list-combination",
    name: "ListCombination",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/ListCombination.vue"),
  },
  {
    path: "/monthly-lender/:id/:month/:year",
    name: "MonthlyLender",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/MonthlyLender.vue"),
  },
  {
    path: "/input-user",
    name: "InputUser",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/InputUser.vue"),
  },
  {
    path: "/input-user/is:role",
    name: "InputUserLB",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/InputUser.vue"),
  },
  {
    path: "/input-user/:type",
    name: "InputUserLBAction",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/InputUser.vue"),
  },
  {
    path: "/edit-user/:role/:id",
    name: "EditUser",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/EditUser.vue"),
  },
  {
    path: "/edit-admin-roles/:id",
    name: "EditAdminRoles",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/EditAdminRoles.vue"),
  },

  {
    path: "/edit-loan/:id",
    name: "EditLoan",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/EditLoan.vue"),
  },
  {
    path: "/edit-loan-lender/:id",
    name: "EditLoanLender",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/EditLoanLender.vue"),
  },
  {
    path: "/edit-payment/:id",
    name: "EditPayment",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/EditPayment.vue"),
  },
  {
    path: "/edit-transaction/:id",
    name: "EditTransaction",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/EditTransaction.vue"),
  },
  {
    path: "/input-loan-lender",
    name: "InputLoanLender",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/InputLoanLender.vue"),
  },
  {
    path: "/input-loan",
    name: "InputLoan",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/InputLoan.vue"),
  },

  {
    path: "/input-transaction",
    name: "InputTransaction",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/InputTransaction.vue"),
  },
  {
    path: "/input-transaction/:status",
    name: "InputTransactionLB",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/InputTransaction.vue"),
  },
  {
    path: "/input-payment",
    name: "InputPayment",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/InputPayment.vue"),
  },
  {
    path: "/report-pusdafil",
    name: "ReportPusdafil",
    meta: { requiresAuth: true },
    component: () => import("../views/Office/ReportPusdafil.vue"),
  },
  {
    path: "/input-borrower-mw/:id",
    name: "InputBorrowerMiddleware",
    meta: { requiresAuth: true },
    component: () => import("../views/Starware/InputBorrowerMiddleware.vue"),
  },
  {
    path: "/list-borrower-mw",
    name: "ListBorrowerMiddleware",
    meta: { requiresAuth: true },
    component: () => import("../views/Starware/ListBorrowerMiddleware.vue"),
  },
  {
    path: "/mail-logs",
    name: "MailLogs",
    meta: { requiresAuth: true },
    component: () => import("../views/BNIGFX/MailLogs.vue"),
  },
  {
    path: "/mail-logs-detail/:id",
    name: "MailLogsDetail",
    meta: { requiresAuth: true },
    component: () => import("../views/BNIGFX/MailLogsDetail.vue"),
  },
  {
    path: "/sftp",
    name: "SFTPRoot",
    meta: { requiresAuth: true },
    component: () => import("../views/BNIGFX/SftpRoot.vue"),
  },
  {
    path: "/sftp/:dir",
    name: "SFTPDirectory",
    meta: { requiresAuth: true },
    component: () => import("../views/BNIGFX/SftpDirectory.vue"),
  },
  {
    path: "/sftp/:dir/:sub",
    name: "SFTPSubdirectory",
    meta: { requiresAuth: true },
    component: () => import("../views/BNIGFX/SftpSubdirectory.vue"),
  },
  {
    path: "/sftp-upload",
    name: "SFTPUpload",
    meta: { requiresAuth: true },
    component: () => import("../views/BNIGFX/SftpUpload.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["auth/isLoggedIn"]) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
