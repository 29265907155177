<template>
  <div>
    <nav-menu :is-drawer-open.sync="isDrawerOpen" />
    <v-app-bar flat absolute color="transparent">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <v-app-bar-nav-icon
            class="d-block d-lg-none me-2"
            color="white"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>

          <v-spacer></v-spacer>

          <div class="white--text caption">alpha version</div>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mdiMagnify, mdiBellOutline, mdiGithub } from "@mdi/js";
import NavMenu from "../Navigation/NavMenu.vue";
import AppBarUserMenu from "./AppBarUserMenu.vue";

export default {
  components: {
    NavMenu,
    AppBarUserMenu,
  },

  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },

  data() {
    return {
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}
</style>
