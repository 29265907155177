import axios from "axios";

const starlord = {
  namespaced: true,

  state: {
    allBorrowersMiddleware: "",
  },

  mutations: {
    basic(state, payload) {
      state[payload.key] = payload.value;
    },
  },

  actions: {
    addNewBorrowerMiddleware({ commit, state }, payload) {
        const addNewBorrowerMiddlewareUrl = process.env.VUE_APP_MIDDLEWARE_URL + "/borrower/register";
        return axios
          .post(addNewBorrowerMiddlewareUrl, payload)
          .then((r) => {
            if (r.data) {
                return r;
              }
          })
          .catch((error) => {
            console.log(error, "error");
          });
      },
      approvalBorrowerMiddleware({ commit, state }, payload) {
        const approvalBorrowerMiddlewareUrl = process.env.VUE_APP_MIDDLEWARE_URL + "/borrower/approval";
        return axios
          .post(approvalBorrowerMiddlewareUrl, payload)
          .then((r) => {
            if (r.data) {
                return r;
              }
          })
          .catch((error) => {
            console.log(error, "error");
          });
      },
      fetchAllBorrowerMiddleware({ commit }, page) {
        const fetchAllBorrowerMiddlewareUrl =
        process.env.VUE_APP_MIDDLEWARE_URL + "/borrower";
        return axios
          .get(fetchAllBorrowerMiddlewareUrl, {
            params: {
                limit: '10',
                sort: 'created_at desc',
                ...(page ? { page: page } : {})
            },
        })
          .then((r) => {
            if (r.data.message == 'success') {
              commit("basic", {
                key: "allBorrowersMiddleware",
                value: r.data.data,
              });
              return r;
            }
          })
          .catch((error) => {
            console.log(error, 'error');
          });
      },
      fetchOneBorrowerMiddleware({ commit }, id) {
        const fetchOneBorrowerMiddlewareUrl =
        process.env.VUE_APP_MIDDLEWARE_URL + "/borrower/detail/" + id;
        return axios
          .get(fetchOneBorrowerMiddlewareUrl)
          .then((r) => {
            if (r.status === 200) {
              return r.data.data;
            }
          })
          .catch((error) => {
            console.log(error, 'error');
          });
      },
  },
};

export default starlord;
